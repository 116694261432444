// import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import IBlogCard from "../../../../types/blog-card";
import CustomLink from "../../button/custom_link";
import BlogCategoryChip from "../blog-category-chip";

function BlogCard({
  image,
  title,
  summary,
  date,
  categories,
  slug,
}: IBlogCard) {
  return (
    <CustomLink to={`/news/${slug}/`}>
      <div className="rounded-[10px] border border-dsc-theme-card-border px-4 py-6 shadow-sm  ">
        {categories && (
          <div className="mb-6 flex flex-wrap gap-2">
            {categories?.map((category) => (
              <BlogCategoryChip
                key={category.title}
                title={category.title}
                slug={category.slug}
              />
            ))}
          </div>
        )}

        <div></div>
        {/* {image.gatsbyImage && (
          <GatsbyImage
            className="mb-6 min-h-full min-w-full overflow-hidden rounded-md object-cover md:min-w-[45%]"
            image={image.gatsbyImage}
            alt={image.alt}
            title={image.alt}
          />
        )} */}

        <h2 className="dsc-h4 mb-6 line-clamp-2 min-h-[72px]">{title}</h2>
        <p className="line-clamp-3">{summary}</p>
        <p className="mt-6 text-dsc-theme-mute">{date}</p>
      </div>
    </CustomLink>
  );
}
export default BlogCard;
