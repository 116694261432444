import React from "react";
import IBlogCard from "../../../../types/blog-card";
import BlogCard from "../blog-card";
import BlogFeatureCard from "../blog-feature";

type BlogSectionProps = {
  title: string;
  featureBlog?: IBlogCard;
  blogs: IBlogCard[];
};

function BlogSection({ title, featureBlog, blogs }: BlogSectionProps) {
  return (
    <section className="px-5 md:px-8">
      <h1 className="dsc-h1 font-bold leading-none mb-12 ">{title}</h1>
      {featureBlog && <BlogFeatureCard {...featureBlog} />}
      <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-3 lg:gap-x-[26px] items-stretch">
        {blogs?.map((blog) => (
          <BlogCard key={blog.slug} {...blog} />
        ))}
      </div>
    </section>
  );
}
export default BlogSection;
