import { Link } from "gatsby";
import React from "react";

const Pagination = ({ pageContext, prefixURL }) => {
  const currentPageNumber = pageContext?.humanPageNumber;
  const totalPages = pageContext?.numberOfPages;
  const startPage = currentPageNumber - 2;
  const endPage = currentPageNumber + 3;
  let isPlaceholderDisplayed = false;
  return (
    <div className="flex flex-wrap items-center justify-center gap-x-4 text-base leading-6 text-dsc-theme-mute">
      <div className="cursor-pointer">
        {currentPageNumber === 1 ? (
          <span>前へ</span>
        ) : (
          <Link
            to={pageContext.previousPagePath}
            className="transition-all duration-200 hover:text-dsc-theme-accent"
          >
            前へ
          </Link>
        )}
      </div>
      {new Array(totalPages).fill(1).map((_, index) => {
        const pageNumber = index + 1;
        if (
          (pageNumber >= startPage && pageNumber <= endPage) ||
          totalPages - pageNumber < 2 ||
          pageNumber < 3
        ) {
          return (
            <Link
              to={`${prefixURL}${pageNumber !== 1 ? pageNumber : ""}`}
              key={index}
            >
              <div
                className={`pagination__item ${
                  pageNumber === pageContext.humanPageNumber &&
                  "flex min-h-[30px] min-w-[30px] items-center justify-center rounded-full bg-[#00ADE4] text-white"
                }`}
              >
                {pageNumber}
              </div>
              {(isPlaceholderDisplayed = false)}
            </Link>
          );
        } else if (!isPlaceholderDisplayed) {
          return (
            <div className="hover:cursor-pointer" key={index}>
              <p>.....</p>
              {(isPlaceholderDisplayed = true)}
            </div>
          );
        } else {
          return <></>;
        }
      })}
      <div className="cursor-pointer">
        {currentPageNumber === totalPages ? (
          <span>次へ</span>
        ) : (
          <Link
            to={pageContext.nextPagePath}
            className="transition-all duration-200 hover:text-dsc-theme-accent"
          >
            次へ
          </Link>
        )}
      </div>
    </div>
  );
};

export default Pagination;
