import React from "react";
import CustomLink from "../../button/custom_link";
export type IBlogCategoryChip = {
  title: string;
  slug: string;
};

function BlogCategoryChip({ title, slug }: IBlogCategoryChip) {
  return (
    <CustomLink
      className="inline-block cursor-pointer rounded-full bg-dsc-theme-link px-[10px]  text-xs leading-6 text-dsc-theme-primary"
      to={slug}
    >
      {title}
    </CustomLink>
  );
}
export default BlogCategoryChip;
