import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../layout";
import IBlogCard from "../types/blog-card";
import BlogSection from "../components/common/news/blog-section";
import SectionSpace from "../components/common/section-space";
import Pagination from "../components/common/pagination";
import SEO from "../components/seo";

const BlogListTemplate = ({ data, pageContext }) => {
  const { numberOfPages } = pageContext;

  const blogs: IBlogCard[] =
    data.allStrapiBlog?.edges.map((e) => {
      const image = {
        alt: e.node.cover.alternativeText,
        gatsbyImage: getImage(e.node.cover.localFile),
      };
      const blog: IBlogCard = {
        image: image,
        slug: e.node.slug,
        title: e.node.title,
        summary: e.node.seo.description,
        date: e.node.published_date,
      };
      return blog;
    }) ?? [];

  const [feature, ...restBlogs] = blogs;

  return (
    <Layout>
      {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
      {/* <pre>{JSON.stringify(blogs, undefined, 2)}</pre> */}

      <div className="px-5 md:px-0 mt-[80px]">
        <BlogSection
          title={"Drone News"}
          // featureBlog={feature}
          blogs={blogs}
        />
      </div>
      <SectionSpace />
      {numberOfPages > 1 && (
        <div className="blog__footer">
          {JSON.stringify(pageContext)}
          <Pagination prefixURL={`/news/`} pageContext={pageContext} />
          <SectionSpace />
        </div>
      )}

      {/* <div className="containerWrapper my-[50px]"></div> */}
    </Layout>
  );
};

export const query = graphql`
  query BlogList($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      sort: { fields: published_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          published_date(locale: "ja", formatString: "ll")
          seo {
            description
          }
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;
export const Head = ({ location }) => {
  const seo = {
    title: "Drone（Drone.io）｜ Blogs",
    description:
      "Drone（Drone.io）は、ビルド・テスト・リリースのワークフロー自動化のためのオープンソースの継続的インテグレーション（CI）プラットフォームです。",
  };
  return <SEO path={location.pathname} {...seo} />;
};
export default BlogListTemplate;
