import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import IBlogCard from "../../../../types/blog-card";
import CustomLink from "../../button/custom_link";

import BlogCategoryChip from "../blog-category-chip";

function BlogFeatureCard({
  image,
  title,
  summary,
  date,
  categories,
  slug,
}: IBlogCard) {
  return (
    <CustomLink to={`/blog/${slug}/`}>
      <div className="mb-10 flex min-h-[274px] w-full flex-col overflow-hidden rounded-[10px] border border-dsc-theme-card-border shadow md:flex-row">
        {image.gatsbyImage && (
          <GatsbyImage
            className="min-h-full min-w-full overflow-hidden rounded-md object-cover md:min-w-[45%]"
            image={image.gatsbyImage}
            alt={image.alt}
            title={image.alt}
          />
        )}

        <div className="min-w-full px-4 py-6 md:min-w-[55%] md:py-[26px] md:px-9">
          <div className="mb-6 flex flex-wrap gap-2">
            {categories?.map((category) => (
              <BlogCategoryChip
                key={category.title}
                title={category.title}
                slug={category.slug}
              />
            ))}
          </div>
          <h2 className="dsc-h2 mb-6 leading-none">{title}</h2>
          <p className="line-clamp-3 text-lg">{summary}</p>
          <p className="mt-6 text-dsc-theme-mute">{date}</p>
        </div>
      </div>
    </CustomLink>
  );
}
export default BlogFeatureCard;
